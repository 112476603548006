// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

p {
    line-height: 1.5;
    margin: 0;
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text-image {
    padding: 100px 0;
    margin: 75px 0;
    position: relative;
    .text-side {
        width: 45%;
        margin-right: 2%;
        padding-left: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-3;
            text-transform: uppercase;
            padding-bottom: 20px;
        }
        p {
            font-weight: 600;
            font-size: $font-size-20;
            color: $color-3;
        }
        a.btn-link {
            background: $color-2;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 63px;
            width: 240px;
            margin-top: 50px;
            transition: 0.3s;
            border-radius: 10px;
            h4 {
                font-weight: bold;
                font-size: $font-size-16;
                color: $color-1;
                transition: 0.3s;
            }
            &:hover {
                background: $color-1;
                transition: 0.3s;
                width: 250px;
                h4 {
                    transition: 0.3s;
                    color: $color-2;
                }
            }
        }
    }
    &::before {
        content: '';
        background: #A5B6A34E;
        z-index: -1;
        width: 80%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        margin-right: 5%;
    }
    @media screen and (max-width: 1600px) {
        .text-side {
            width: 50%;
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1200px) {
            padding: 75px 5% 25px 5%;
            margin: 75px 0 25px 0;
            flex-direction: column;
            .text-side {
                width: 100%;
                padding: 0;
                margin: 0 0 50px 0;
            }
            &::before {
                width: 100%;
                height: 65%;
            }
            @media screen and (max-width: $size-xs-max) {
                .img-responsive {
                    border-top-left-radius: 30px;
                    border-top-right-radius: 30px;
                }
            }
        }
    }
}

#section-services {
    padding: 50px 5% 75px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-30;
        color: $color-1;
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 50px;
    }
    .section-bloc {
        .bloc {
            background: $color-1;
            width: 400px;
            height: 445px;
            padding: 25px;
            h2 {
                font-weight: bold;
                font-size: $font-size-120;
                color: $color-2;
                text-transform: uppercase;
                padding-bottom: 65px;
            }
            h3 {
                font-weight: bold;
                font-size: $font-size-36;
                text-transform: initial;
                color: $color-2;
            }
        }
    }
    .section-btn-arrow {
        padding-top: 60px;
        a.left-side.btn-link {
            background: $color-1;
            width: 250px;
            height: 50px;
            transition: 0.3s;
            .icon {
                position: relative;
                img {
                    &:nth-child(1) {
                        opacity: 1;
                    }
                    &:nth-child(2) {
                        position: absolute;
                        opacity: 0;
                        top: -4px;
                        left: -4px;
                    }
                }
            }
            .text {
                h5 {
                    font-family: $font-family-3;
                    font-size: $font-size-14;
                    color: $color-white;
                    text-transform: uppercase;
                }
            }
            &:hover {
                background: $color-2;
                width: 255px;
                height: 55px;
                transition: 0.3s;
                img {
                    &:nth-child(1) {
                        opacity: 0;
                    }
                    &:nth-child(2) {
                        opacity: 1;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1550px) {
        .section-bloc {
            .bloc {
                height: 390px;        
            }
        }
        @media screen and (max-width: 1450px) {
            .section-bloc {
                flex-wrap: wrap;
                justify-content: center;
                .bloc {
                    width: 40%;
                    margin: 25px;
                }
            }
            @media screen and (max-width: 1024px) {
                .section-bloc {
                    .bloc {
                        width: 42%;
                        height: 320px;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 50px 5%;
                    .section-bloc {
                        justify-content: initial;
                        .bloc {
                            width: 100%;
                            height: 320px;
                            margin: 0 0 25px 0;
                        }
                    }
                    .section-btn-arrow {
                        padding-top: 50px;
                        flex-direction: column;
                        a.right-side.arrow.scroll-link {
                            margin-top: 50px;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* PROFIL CORPORATIF
*******************************************************************************/

#section-image-text {
    padding: 100px 0;
    margin: 75px 0;
    position: relative;
    .text-side {
        width: 45%;
        margin-left: 3%;
        padding-right: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-3;
            text-transform: uppercase;
            padding-bottom: 20px;
        }
        .section-list {
            padding-top: 35px;
            .bloc {
                .text {
                    width: 100%;
                    padding-left: 25px;
                    p {
                        font-family: $font-family-2;
                        font-weight: 600;
                        font-size: $font-size-20;
                        color: $color-1;
                    }
                }
            }
        }
    }
    &::before {
        content: '';
        background: #A5B6A30D;
        z-index: -1;
        width: 80%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
    @media screen and (max-width: 1600px) {
        .text-side {
            width: 50%;
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1550px) {
        .text-side {
            width: 60%;
        }
            @media screen and (max-width: 1200px) {
                padding: 75px 5% 50px 5%;
                margin: 75px 0 25px 0;
                flex-direction: column;
                .text-side {
                    width: 100%;
                    padding: 0;
                    margin: 50px 0 0 0;
                }
                &::before {
                    width: 100%;
                    height: 65%;
                    bottom: 0;
                    top: initial;
                }
                @media screen and (max-width: $size-sm-max) {
                    margin: 0 0 25px 0;
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES OFFERTS
*******************************************************************************/

#section-image-text.services {
    .text-side {
        .section-list {
            padding-top: 20px;
        }
    }
}

#section-text-images {
    padding: 100px 0;
    margin: 75px 0;
    position: relative;
    .text-side {
        width: 47%;
        margin-right: 3%;
        padding-left: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-3;
            text-transform: uppercase;
            padding-bottom: 20px;
        }
        .section-list {
            padding-top: 35px;
            .bloc {
                .text {
                    width: 100%;
                    padding-left: 25px;
                    p {
                        font-family: $font-family-2;
                        font-weight: 600;
                        font-size: $font-size-20;
                        color: $color-1;
                    }
                }
            }
        }
    }
    &::before {
        content: '';
        background: #CEDC8F31;
        z-index: -1;
        width: 80%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    @media screen and (max-width: 1600px) {
        .text-side {
            width: 50%;
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1550px) {
        .text-side {
            width: 60%;
        }
            @media screen and (max-width: 1200px) {
                padding: 75px 5% 25px 5%;
                margin: 75px 0 25px 0;
                flex-direction: column;
                .text-side {
                    width: 100%;
                    padding: 0;
                    margin: 0 0 50px 0;
                }
                &::before {
                    width: 100%;
                    height: 75%;
                }
                @media screen and (max-width: $size-sm-max) {
                    margin: 0 0 50px 0;
                }
            }
        }
    }
}

#section-background {
    padding: 0 5%;
    .image {
        position: relative;
        .text {
            position: absolute;
            top: 45%;
            padding-left: 3%;
            h3 {
                font-weight: bold;
                font-size: $font-size-40;
                color: $color-white;
            }
        }
    }
}

#section-text {
    padding: 75px 5%;
    .left-side {
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-3;
        }
    }
    .right-side {
        border-left: 1px solid $color-2;
        padding-left: 50px;
        p {
            font-family: $font-family-2;
            font-size: $font-size-20;
            color: #202020;
        }
    }
    @media screen and (max-width: 1500px) {
        .left-side {
            width: 39%;
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .left-side {
                width: 100%;
                margin-bottom: 50px;
            }
            .right-side {
                border-left: none;
                padding-left: 0;
                width: 100%;
            }
            a.arrow.scroll-link {
                margin-left: auto;
                margin-top: 50px;
            }
        }
    }
}

/*******************************************************************************
* CONTACTEZ-NOUS
*******************************************************************************/

#section-coordonnees {
    padding: 0 5% 75px 5%;
    margin-top: -350px;
    position: relative;
    .left-side {
        width: 55%;
        padding: 50px 7%;
        margin-right: 25px;
        background: $color-white;
        h2 {
            font-weight: bold;
            font-size: $font-size-40;
            padding-bottom: 25px;
        }
        p {
            font-family: $font-family-2;
            font-weight: normal;
            font-size: $font-size-20;
            color: #202020;
            padding-bottom: 85px;
        }
        h3 {
            font-weight: bold;
            font-size: $font-size-26;
            padding-bottom: 35px;
            text-transform: uppercase;
            color: $color-1;
            &.last {
                margin-top: 350px;
            }
        }
    }
    .right-side {
        width: 45%;
        padding: 50px 4%;
        background: $color-white;
        h2 {
            font-weight: bold;
            font-size: $font-size-30;
            padding-bottom: 50px;
        }
        .section-info {
            h3 {
                font-weight: bold;
                font-size: $font-size-40;
                text-transform: initial;
                color: $color-1;
                padding-bottom: 30px;
            }
            .bloc {
                .text {
                    width: 100%;
                    margin-left: 20px;
                }
            }
            h4 {
                font-family: $font-family-2;
                font-weight: bold;
                font-size: $font-size-20;
                color: #202020;
                text-transform: uppercase;
                padding-bottom: 7px;
            }
            h5 {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-20;
                color: #202020;
            }
        }
        .section-accreditation {
            padding-top: 75px;
            h2 {
                padding-bottom: 5px;
            }
        }
    }
    h2 {
        font-weight: revert;
        color: $color-1;
    }
    @media screen and (max-width: 1810px) {
        .right-side {
            .section-courriel {
                flex-direction: column;
                .right {
                    padding-top: 15px;
                }
            }
        }
        @media screen and (max-width: 1450px) {
            .left-side {
                padding: 50px 5%;
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column-reverse;
                margin-top: -260px;
                .right-side {
                    width: 85%;
                    padding: 50px 5%;
                    margin: auto;
                }
                .left-side {
                    width: 100%;
                }
                @media screen and (max-width: 1024px) {
                    margin-top: -200px;
                    .right-side {
                        width: 90%;
                    }
                    @media screen and (max-width: $size-sm-max) {
                        margin-top: -140px;
                        .right-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    

}

@media screen and (max-width: $size-xs-max) {
   

}
