.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: 35%;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        h2 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-white;
            text-transform: uppercase;
        }
        .text {
            padding: 25px 0 50px 0;
            h4 {
                font-family: $font-family-2;
                font-size: $font-size-26;
                font-weight: 600;
                color: $color-white;
                line-height: 1.5;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
        }
        a.btn-link {
            background: $color-2;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 63px;
            width: 240px;
            margin: auto;
            transition: 0.3s;
            border-radius: 10px;
            h4 {
                font-weight: bold;
                font-size: $font-size-16;
                color: $color-1;
                transition: 0.3s;
            }
            &:hover {
                background: $color-1;
                transition: 0.3s;
                width: 250px;
                h4 {
                    transition: 0.3s;
                    color: $color-2;
                }
            }
        }
        @media screen and (max-width: 1175px) {
            h2 {
                font-size: 2.8rem;
            }
            .text {
                h4 {
                    font-size: 1.15rem;
                }
            }
            @media screen and (max-width: 1075px) {
                h2 {
                    font-size: 2.5rem;
                }
                .text {
                    h4 {
                        font-size: 1rem;
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    top: 22%;
                    h2 {
                        font-size: 2.15rem;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        top: 18%;
                        h2 {
                            font-size: 2.8rem;
                        }
                        .text {
                            h4 {
                                font-size: 1.05rem;
                            }
                        }
                        @media screen and (max-width: 390px) {
                            top: 14%;
                        }
                    }
                }
            }
        }
    }
    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
        	}
      }
}
