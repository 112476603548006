.btn {
    background-color: $color-2;
    color: $color-1;
    border: none;
    border-radius: 5px;
    width: 215px;
    height: 75px;
    padding: 15px;
    font-family: $font-family-1;
    font-weight: bold;
    font-size: $font-size-26;
    width: 100%;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-1;
        color: $color-2;
        width: 220px;
    }
}
