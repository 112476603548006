.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            font-size: 5vw;
            font-weight: 400;
            color: $color-text-contrast;
            //text-shadow: 0 0 10px $color-black;
        }
    }
}
@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
            &--title {
                
            }
            &--subtitle {
               
            }
        }
    }
}

.module-header.corporatif {
    .module-header {
        &--caption {
            background: #fff;
            left: 0;
            bottom: 0;
            width: 815px;
            height: 255px;
            padding: 50px 5%;
            top: initial;
            transform: initial;
            .text {
                p {
                    font-family: $font-family-2;
                    font-size: $font-size-20;
                    color: #202020;
                    line-height: 1.3;
                    margin: 0;
                    strong {
                        color: #202020;
                    }
                }
            }
            .border {
                background: #202020;
                width: 75px;
                height: 2px;
                margin-top: 25px;
            }
            @media screen and (max-width: 1400px) {
                width: 725px;
                padding: 50px 3%;
                @media screen and (max-width: 1024px) {
                    width: 550px;
                    height: 255px;
                    @media screen and (max-width: $size-sm-max) {
                        height: initial;
                        position: initial;
                        width: 100%;
                        padding: 50px 5%;
                    }
                }
            }
        }
    }
}


.module-header.services {
    .module-header {
        &--caption {
            position: absolute;
            top: 41%;
            width: 100%;
            transform: translateY(-50%);
            &--title {
                width: 100%;
                font-weight: bold;
                font-size: $font-size-60;
                text-transform: initial;
                text-align: center;
                color: $color-white;
                //text-shadow: 0 0 10px $color-black;
            }
            @media screen and (max-width: 1024px) {
                top: 37%;
                &--title {
                    font-size: 2.5rem;
                }
            }
        }
    }
    .text {
        background: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 815px;
        height: 255px;
        padding: 50px 5%;
        top: initial;
        transform: initial;
        .border {
            background: #202020;
            width: 75px;
            height: 2px;
            margin-bottom: 25px;
        }

        p {
            font-family: $font-family-2;
            font-size: $font-size-20;
            color: #202020;
            line-height: 1.3;
            margin: 0;
            strong {
                color: #202020;
            }
        }
        @media screen and (max-width: 1550px) {
            width: 700px;
            height: 225px;
            padding: 50px 3%;
            @media screen and (max-width: $size-md-max) {
                height: initial;
                position: initial;
                width: 100%;
                padding: 50px 5%;
            }
        }   
    }
}

