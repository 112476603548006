footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;

        .section-logo {
            .copyright {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-14;
                text-transform: uppercase;
                color: #202020;
            }
        }
        .section-menu-footer {
            a.link {
                font-family: $font-family-2;
                font-weight: bold;
                font-size: $font-size-14;
                color: #202020;
            }
        }
        @media screen and (max-width: 1140px) {
            flex-direction: column-reverse;
            .section-menu-footer {
                padding: 25px 0;
            }
            @media screen and (max-width: $size-xs-max) {
                .section-menu-footer {
                    flex-direction: column;
                    a.link {
                        padding: 10px;
                        text-align: center;
                    }
                    a.link.ml10 {
                        margin: auto;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1140px) {
        height: 250px;
        @media screen and (max-width: $size-xs-max) {
            height: 350px;
        }
    }
}
